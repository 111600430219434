<template>
  <div id="device">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">智能监测 </span>
          <dv-decoration-3 style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            " />
        </div>
      </div>
      <!-- <div class="d-flex jc-center">
        <CentreLeft1Chart />
      </div> -->
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div v-for="item in numberData"
             class="item-box"
             :key="item.text">
          <div class="device_img">
            <img :src="item.image"
                 alt="" />
          </div>
          <div class="device_date">
            <p>
              <span class="prblem_num">{{ item.online }}</span><span class="total_num">/{{ item.total }}</span>
            </p>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pro: {
      type: Object,
    },
  },
  data () {
    return {
      numberData: [],
    };
  },
  components: {},
  mounted () {
    this.oppro();
    this.changeTiming();
  },
  watch: {
    pro: {
      deep: true,
      handler () {
        this.oppro();
        this.changeNumber();
      }
    }
  },
  created () { },
  methods: {
    oppro () {
      this.numberData = [];
      if (this.pro.Face) {
        let face = {
          total: 0,
          online: 0,
          text: "人脸机",
          image: require("@/assets/image/position-door.png"),
        };
        this.pro.Face.forEach((item) => {
          face.total += 1;
          if (item.Status == 2) {
            face.online += 1;
          }
        });
        this.numberData.push(face);
      }
      if (this.pro.Video) {
        let video = {
          total: 0,
          online: 0,
          text: "视频监控",
          image: require("@/assets/image/position-video.png"),
        };
        this.pro.Video.forEach((item) => {
          video.total += 1;
          if (item.Status == 0) video.online += 1;
        });
        this.numberData.push(video);
      }
      if (this.pro.Env) {
        let env = {
          total: 0,
          online: 0,
          text: "扬尘",
          image: require("@/assets/image/position-evn.png"),
        };
        this.pro.Env.forEach((item) => {
          env.total += 1;
          if (item.Status == 2) {
            env.online += 1;
          }
        });
        this.numberData.push(env);
      }
      if (this.pro.Tower) {
        let tower = {
          total: 0,
          online: 0,
          text: "塔吊",
          image: require("@/assets/image/position-tower.png"),
        };
        this.pro.Tower.forEach((item) => {
          tower.total += 1;
          if (item.Status == 2) {
            tower.online += 1;
          }
        });
        this.numberData.push(tower);
      }
      if (this.pro.Elevator) {
        let elevator = {
          total: 0,
          online: 0,
          text: "升降机",
          image: require("@/assets/image/position-elevator.png"),
        };
        this.pro.Elevator.forEach((item) => {
          elevator.total += 1;
          if (item.Status == 2) {
            elevator.online += 1;
          }
        });
        this.numberData.push(elevator);
      }
    },
    changeTiming () {
      setInterval(() => {
        this.changeNumber();
      }, 30000);
    },
    changeNumber () {
      this.$http
        .post("/DeviceManage/DeviceBase/GetDeviceCount", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.numberData = [];
            if (resJson.Data.face) {
              let face = {
                total: resJson.Data.face.Total,
                online: resJson.Data.face.Online,
                text: "人脸机",
                image: require("@/assets/image/position-door.png"),
              };
              this.numberData.push(face);
            }
            if (resJson.Data.video) {
              let face = {
                total: resJson.Data.video.Total,
                online: resJson.Data.video.Online,
                text: "视频监控",
                image: require("@/assets/image/position-video.png"),
              };
              this.numberData.push(face);
            }
            if (resJson.Data.env) {
              let face = {
                total: resJson.Data.env.Total,
                online: resJson.Data.env.Online,
                text: "扬尘",
                image: require("@/assets/image/position-evn.png"),
              };
              this.numberData.push(face);
            }
            if (resJson.Data.tower) {
              let tower = {
                total: resJson.Data.tower.Total,
                online: resJson.Data.tower.Online,
                text: "塔吊",
                image: require("@/assets/image/position-tower.png"),
              };
              this.numberData.push(tower);
            }
            if (resJson.Data.unloadingPlatform) {
              let unloadingPlatform = {
                total: resJson.Data.unloadingPlatform.Total,
                online: resJson.Data.unloadingPlatform.Online,
                text: "料台",
                image: require("@/assets/image/position-unloading.png"),
              };
              this.numberData.push(unloadingPlatform);
            }
            if (resJson.Data.elevator) {
              let elevator = {
                total: resJson.Data.elevator.Total,
                online: resJson.Data.elevator.Online,
                text: "升降机",
                image: require("@/assets/image/position-elevator.png"),
              };
              this.numberData.push(elevator);
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
#device {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-top: 20px;
    .item-box {
      width: 33%;
      height: 4vh;
      margin-bottom: 2vh;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      .device_img {
        width: 4vh;
        height: 4vh;
        transition: 0.5s;
        margin-left: 10px;
        img {
          transform: translateX(5px);
          width: 100%;
        }
      }
      .device_date {
        width: 60px;
        text-align: left;
        font-size: 12px;
        color: #b6c8ee;
        .prblem_num {
          font-size: 20px;
          color: #ff0;
        }
        .total_num {
          font-size: 12px;
          color: #01d2d7;
        }
      }
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>