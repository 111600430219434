<template>
  <div id="projectPic">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">全景照片</span>
        </div>
      </div>
      <div class="bg-color-pic">
        <ic-slider :autoplay="3000">
          <ic-slider-item v-for="item in images"
                          :key="item.Id">
            <img :src="item.ImagePath" />
          </ic-slider-item>
        </ic-slider>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-better-slider/style.css";
import { IcSlider, IcSliderItem } from "vue-better-slider";
export default {
  data () {
    return {
      images: [],
    };
  },
  props: {
    pro: {
      type: Object,
    },
  },
  components: {
    IcSlider,
    IcSliderItem,
  },
  watch: {
    pro: {
      deep: true,
      handler () {
        //this.oppro()
        this.init()
      }
    }
  },
  mounted () {
    // this.oppro();
    this.init();
  },
  methods: {

    init () {
      this.images = []
      this.$http
        .post("/Projects/ProImage/GetByPro", {
          ProId: this.pro.Id,
          ImageType: 0,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.images = resJson.Data;
          }
        });
    },
  },
};
</script>

<style lang="scss">
#projectPic {
  padding: 0.3rem 0.2rem;
  height: 7.7rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 7.1625rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .bg-color-pic {
    img {
      height: 6.9625rem;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
}
</style>