<template>
  <div id="projectProgress">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <div class="d-flex">
          <span class="fs-xl text mx-2">工程进度</span>
        </div>
      </div>
      <div class="d-flex pt-2 pl-2"
           style="padding: 0.75rem 0px; height: 3.55rem; color: white">
        <a-steps :current="this.progressInfo.length - 1">
          <a-popover slot="progressDot"
                     slot-scope="{ index, status, prefixCls }">
            <template slot="content"> </template>
            <span :class="`${prefixCls}-icon-dot`" />
          </a-popover>
          <a-step v-for="item in progressInfo"
                  :key="item.Id"
                  v-bind:title="item.Date"
                  v-bind:description="item.Title">
          </a-step>
          <a-step status="wait"
                  v-bind:title="pro.End"
                  description="完工">
          </a-step>
        </a-steps>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pro: {
      type: Object,
    },
  },
  data () {
    return {
      progressInfo: [],
    };
  },
  components: {},
  watch: {
    pro: {
      deep: true,
      handler () {
        this.oppro();
      }
    }
  },
  created () { },
  mounted () {
    this.oppro();
  },
  methods: {
    oppro () {
      //this.pro = ProInfoCache.getProInfo()
      if (this.pro.EstEndDate) {
        this.pro.End = ''
      }
      this.progressInfo = []
      this.$http
        .post("/Projects/ProEvent/GetDataList", {
          Search: {
            ProId: this.pro.Id,
          }
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.progressInfo = resJson.Data;
          }
        });
    },
  },
};
</script>

<style lang="scss">
#projectProgress {
  padding: 0.3rem 0.2rem;
  height: 3.55rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 2.0625rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 3.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
  .ant-steps-item-title,
  .ant-steps-item-description {
    color: rgb(253 249 249 / 65%);
  }
  .ant-steps-icon-dot {
    background: #c3cbde;
  }
}
</style>