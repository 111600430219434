<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <Menu name="project"
              @change="onProInfoChange($event)" />
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else>
          <a-row>
            <a-col :xs="20"
                   :sm="16"
                   :md="12"
                   :lg="8"
                   :xl="6">
              <div>
                <dv-border-box-13>
                  <project-info :data="pro" />
                </dv-border-box-13>
              </div>
              <div>
                <dv-border-box-12>
                  <devices :pro="pro" />
                </dv-border-box-12>
              </div>
            </a-col>
            <a-col :xs="2"
                   :sm="4"
                   :md="6"
                   :lg="8"
                   :xl="12">
              <dv-border-box-12>
                <projectPic :pro="pro" />
              </dv-border-box-12>
              <dv-border-box-12><project-progress :pro="pro" /></dv-border-box-12>
            </a-col>
            <a-col :xs="20"
                   :sm="16"
                   :md="12"
                   :lg="8"
                   :xl="6">
              <div>
                <dv-border-box-13>
                  <realName :pro="pro" />
                </dv-border-box-13>
              </div>
              <div>
                <dv-border-box-12>
                  <div class="attendanceRate">
                    <div class="down">
                      <div class="percent">
                        <vipCard options="cardColorNone">
                          <!-- <vipCard > -->
                          <div class="headerBox"
                               slot="header">
                            <div class="headerImgname">
                              <div class="face">
                                <img alt=""
                                     style="width: 100%"
                                     :src="this.SafetyHelmetWaring.Resource" />
                              </div>
                              <div class="card-info"
                                   style="">
                                <div class="name">
                                  预警类别:<span>{{
                                    this.SafetyHelmetWaring.Alarmype
                                  }}</span>
                                </div>

                                <div class="vipka">
                                  时间:<span>{{
                                    this.SafetyHelmetWaring.Date
                                  }}</span>
                                </div>
                              </div>
                              <div class="card-derection">
                                <div>安全帽预警</div>
                              </div>
                            </div>
                          </div>
                        </vipCard>
                      </div>
                    </div>
                  </div>
                  <div class="attendanceRate">
                    <div class="down">
                      <div class="percent">
                        <vipCard options="cardColorNone">
                          <!-- <vipCard > -->
                          <div class="headerBox"
                               slot="header">
                            <div class="headerImgname">
                              <div class="face">
                                <img alt=""
                                     style="width: 100%"
                                     :src="this.SlagCarWaring.Resource" />
                              </div>
                              <div class="card-info"
                                   style="">
                                <div class="name">
                                  车牌号:<span>{{
                                    this.SlagCarWaring.CarNo
                                  }}</span>
                                </div>
                                <div class="vipka">
                                  时间:<span>{{
                                    this.SlagCarWaring.Date
                                  }}</span>
                                </div>
                              </div>
                              <div class="card-derection">
                                <div>渣车抓拍</div>
                              </div>
                            </div>
                          </div>
                        </vipCard>
                      </div>
                    </div>
                  </div>
                </dv-border-box-12>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import ProInfoCache from "@/utils/cache/ProInfoCache"
import devices from "./devices";
import projectInfo from "./projectInfo.vue"
import projectPic from "./projectPic";
import projectProgress from "./projectProgress";
import realName from "./realName";
import vipCard from "@/components/Card/attendanceCard";

export default {
  data () {
    return {
      loading: true,
      SafetyHelmetWaring: {},
      SlagCarWaring: {},
      pro: {}
    };
  },
  components: {
    Menu,
    devices,
    projectPic,
    projectProgress,
    realName,
    vipCard,
    projectInfo
  },

  created: function () {
    let connection = this.$op.getConnect(this);
    connection.on("safetyHelmetWarning_attence", (x) => {
      this.SafetyHelmetWaring = x;
    });
    connection.on("slagcarWarning_attence", (x) => {
      this.SlagCarWaring = x;
    });
  },
  mounted () {
    this.pro = ProInfoCache.getProInfo()
    this.initForm()
  },
  methods: {
    onProInfoChange (e) {
      this.pro = e;
      // this.pro = ProInfoCache.getProInfo()
      console.log(e)
      console.log(this.pro)
      this.initForm()
    },
    initForm () {
      this.SafetyHelmetWaring = {}
      this.SlagCarWaring = {}
      this.$http
        .post("/DeviceManage/DeviceSafetyhelmetWarning/GetTheLastData", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          if (resJson && resJson.Data) {
            this.SafetyHelmetWaring = resJson.Data;
          }
        });
      this.$http
        .post("/DeviceManage/DeviceSlagcarWarningRecord/GetTheLastData", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          if (resJson && resJson.Data) {
            this.SlagCarWaring = resJson.Data;
          }
        });
      this.cancelLoading();
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>
<style scoped  lang="scss">
@import "@/assets/scss/index.scss";

.attendanceRate {
  padding: 0.0625rem;
  /* height: 5rem; */
  min-width: 6.05rem;

  .down {
    .percent {
      .cardColorNone {
        .headerBox {
          .headerImgname {
            .face {
              /*  padding: 25px; */
              /* width: 1.75rem; */
              display: inline-block;
              width: 100%;
              max-width: 100%;
              height: auto;
            }
            .card-info {
              top: 14%;
              left: 33%;
              position: absolute;
              font-size: 18px;
              letter-spacing: 1px;
              color: rgb(252, 252, 253);
              font-family: "Open Sans", sans-serif;
              z-index: 100;
              line-height: 25px;
            }
            .card-derection {
              top: 0;
              right: 5%;
              position: absolute;
              font-size: 18px;
              letter-spacing: 1px;
              color: rgb(10 10 10);
              font-family: "Open Sans", sans-serif;
              z-index: 100;
              line-height: 25px;
              background: #1a5cd7;
              padding: 4px 11px;
            }
          }
        }
      }
    }
  }
}
.body-box-left {
  float: left;
  width: 23%;
}
.body-box-center {
  margin: 0 auto;
  width: 46%;
}

.bottom-data {
  .item-box {
    /* float: right;
    position: relative;
    width: 50%; */
    color: #d3d6dd;
    // 金币
    .coin {
      position: absolute;
      left: 0.1rem;
      top: 0.2125rem;
      font-size: 0.25rem;
      color: #ffc107;
    }
    .colorYellow {
      color: yellowgreen;
    }
  }
}
</style>